<template>
  <section :class="{ InputDropdown: true, 'form-group': true, row: !!label }">
    <div class="col-sm-4" v-if="label">
    <InputLayout
      :input-id="$static.input.id"
      :label="label"
      :subtext="subtext"
      :arrangement="arrangement"
      :error="error"
    >
    </InputLayout>
    </div>
    <div :class="{'col-sm-8' : !!label }">
      <Multiselect
        multiple
        :value="value"
        :label="trackLabel"
        :searchable="searchable"
        :show-labels="false"
        :close-on-select="!keepOpen"
        @select="emitSelect($event)"
        @remove="emitRemove($event)"
        @search-change="onSearchChange($event)"
        v-bind="{...$props, ...$attrs}"
        open-direction="bottom"
        :loading="isLoading"
        :show-no-results="false"
        :track-by="trackBy"
      >
        <template v-slot:selection="slot">
          <slot name="selection" v-bind="slot"/>
        </template>
      </Multiselect>
    </div>
  </section>
</template>

<script>
import InputLayout from "@/components/Inputs/components/InputLayout.component";
import InputMixin from "@/components/Inputs/mixins/input.mixin";
import LayoutMixin from "@/components/Inputs/mixins/layout.mixin";
import Multiselect from 'vue-multiselect';

export default {
  name: 'DDMCAF-InputDropdown',
  components: {InputLayout, Multiselect},
  mixins: [InputMixin, LayoutMixin],
  props: {
    value: {type: null, default: ''},
    options: {type: Array, default: null},
    trackLabel: {type: String, default: ''},
    trackBy: {type: String, default: ''},
    searchable: {type: Boolean, default: true},
    keepOpen: {type: Boolean, default: false},
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    emitSelect(event) {
      const value = this.value && Array.isArray(this.value)
        ? (Array.isArray(event) ? [...this.value, ...event] : [...this.value, event])
        : (Array.isArray(event) ? [...event] : [event]);
      this.$emit('input', value);
    },
    emitRemove(event) {
      const track = this.trackBy || this.trackLabel;
      let value;
      if(Array.isArray(event)){
        const tracked = event.map(item => item[track]);
        value = this.value.filter(item => !tracked.includes(item[track]));
      }
      else {
        value = this.value.filter(item => item[track] !== event[track]);
      }
      this.$emit('input', value);
    },
    onSearchChange(keyword) {
      this.getTeams(keyword)
    },
    getTeams(keyword) {
      let params = {}
      if (keyword) {
        params = {name: keyword}
      }
      this.$store.dispatch('teamsList/getTeams', params).then(() => {
        this.isLoading = false
      })
    },
  }
}
</script>

<style lang="scss">
@import "src/styles/main.scss";

.InputDropdown {
  .multiselect {width: 200px}
  .multiselect--active {
    .multiselect__select {
      padding-bottom: 7px;
    }
  }
  .multiselect__tags {
    border: 1px solid #D8DAE3;
    padding-top: 11px;
    padding-left: 10px;
  }
  .multiselect__single {color: #383838}
  .multiselect__placeholder,
  .multiselect__input {
    font-weight: 600 !important;
  }
  .multiselect__input::placeholder {
    font-weight: 600 !important;
    color: #AFB1BC;
  }
  .multiselect__option {
    padding-left: 16px;
    font-size: 12px;
    font-weight: 600 !important;
  }
  .multiselect__option--group {
    color: inherit;
    font-weight: 700 !important;
  }
  .multiselect__option--group:not(.multiselect__option--group-selected):not(.multiselect__option--highlight) {
    background-color: transparent;
  }
  .multiselect__option--selected,
  .multiselect__option--group-selected {
    background-color: #D8DAE3;
  }
  .multiselect__option--highlight {
    background-color: $primary-color;
    color: white;
    font-weight: 700 !important;
  }
  .multiselect__placeholder {
    padding-left: 5px !important;
    padding-top: 4px !important;
  }
  .multiselect__select {top: 2px}
  .multiselect, .multiselect__tags, .multiselect__option {min-height: 43px}
  .multiselect, .multiselect__placeholder, .multiselect__input, .multiselect__single, .multiselect__element {font-size: 12px}
  .multiselect__content-wrapper, .multiselect__select {transition-duration: 0s}
  .multiselect__select {height: 35px}
  .multiselect__tags {
    border-radius: 12px;
    font-weight: 700;
  }
  .multiselect__tag {
    background-color: $primary-color;
  }
  .multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background-color: $secondary-color;
  }
  .multiselect__placeholder {
    font-weight: normal;
    padding-left: 3px;
    padding-top: 3px;
  }
  .multiselect--disabled {
    background: transparent;
  }
  .multiselect--disabled .multiselect__select {
    display: none;
  }
}
</style>
