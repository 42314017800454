<template>
  <div class="ContactsPopup">
    <div class="ContactsPopup__window">
      <div class="ContactsPopup__window-container" style="padding: 20px">
      <div class="ContactsPopup__close-popup">
        <span @click="closePopup">X</span>
      </div>

      <Headline :size="4">{{type === 'create' ? 'Create new' : 'Update'}} {{this.contactType === 'delivery' ? 'delivery contact' : 'main contact'}}</Headline>

      <div class="ContactsPopup__fields">
        <InputDropdown
            v-model="model.season"
            track-by="seasonId"
            track-label="optionLabel"
            :options="this.competitionSeasons"
            label="Season*"
            @input="handleSeasonChange()"
        />
        <InputDropdownMultiselect
          placeholder="Select Team"
          v-model="model.teams"
          trackLabel="optionTitle"
          :options="teams"
          label="Team(s)*"
          trackBy="teamId"
          keepOpen
        />

        <Input
          label="First name*"
          placeholder="First name"
          v-model="model.firstname"
        />
        <Input
          label="Last name*"
          placeholder="Last name"
          v-model="model.lastname"
        />
        <Input
          label="Email*"
          placeholder="Email"
          v-model="model.email"
        />
        <InputPhone
            label="Mobile*"
            :value="model.phone"
            @input="handlePhoneChange($event)"
            @country="(model.phoneCountry = $event)"
        />
        <Input
          :label="hasExtendedValidation ? 'Address*' : 'Address'"
          placeholder="Building, Street and Street number"
          v-model="model.address"
        />
        <Input
          :label="hasExtendedValidation ? 'City*' : 'City'"
          :placeholder="hasExtendedValidation ? 'City' : 'City (optional)'"
          v-model="model.city"
        />
        <Input
          :label="hasExtendedValidation ? 'Zip code*' : 'Zip code'"
          :placeholder="hasExtendedValidation ? 'Zip code' : 'Zip code (optional)'"
          v-model="model.zip"
        />

        <InputDropdown
          v-model="model.country"
          track-by="id"
          track-label="country"
          :options="this.countryOptions"
          :label="hasExtendedValidation ? 'Country/Region*' : 'Country/Region'"
        />
      </div>

      <div class="ContactsPopup__errors">
        <div v-for="value in errors">
          {{value}}
        </div>
      </div>

      <div class="btn-wrapper">
        <div class="SectionControls__policy-text"  v-if="type === 'create'">
          <p class="policy-text" v-html="policyText"></p>
        </div>
        <Button
          v-if="type === 'create'"
          type="primary"
          :disabled="isValid"
          @click="createContact(model)"
        >
          Create
        </Button>

        <Button
          v-if="type === 'update'"
          type="primary"
          :disabled="isValid"
          @click="updateContact(model)"
        >
          Update
        </Button>
        <pulse-loader :loading="showLoader" :color="color" :size="size" ></pulse-loader>
      </div>

      <label class="d-block mt-3 mb-n3 p-3 bg-light rounded" v-if="contactType !== 'delivery' && type === 'create'">
        <input
          type="checkbox"
          v-model="model.useForDelivery"
        />
        Delivery Contact information is same as Main Contact
      </label>
      </div>
    </div>
  </div>
</template>

<script>
import {ContactModel} from "@/classes/models/Contact.model";

import Button from "@/components/Button/Button.component";
import Headline from "@/components/Headline/Headline.component";
import Input from "@/components/Inputs/Input.component";
import InputDropdown from "@/components/Inputs/InputDropdown.component";
import InputDropdownMultiselect from '@/components/Inputs/InputDropdownMultiselect.component';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import get from "lodash.get";
import InputPhone from "@/components/Inputs/InputPhone.component";
import Checkbox from "@/components/Checkbox/Checkbox.component.vue";

export default {
  name: "DDMCAF-ContactsPopup",
  components: {
    Headline,
    Input,
    InputDropdown,
    PulseLoader,
    InputDropdownMultiselect,
    Button,
    InputPhone,
    Checkbox,
  },
  data: () => ({
    model: new ContactModel(),
    showLoader: false,
    color: "#00A9A0",
    size: "12px",
    errors: []
  }),
  props: ['type', 'contact', 'contactType'],
  computed: {
    competitionSeasons() {
      return get(this, '$store.state.event.competitionSeasonsList', [])
    },
    seasons() {
      console.log(get(this, '$store.state.event.seasonsList', []))
      return get(this, '$store.state.event.seasonsList', [])
    },
    teams() {
      return get(this, '$store.state.teamsList.teamsArray')
    },
    countryOptions() {
      return get(this, '$store.state.countriesList.countryOptions')
    },
    hasExtendedValidation(){
      return (this.contactType === 'delivery' || this.model.useForDelivery);
    },
    isValid() {
      let fields = ['firstname', 'lastname', 'email', 'phone', 'teams']
      if (this.hasExtendedValidation) {
        fields = [...fields, ...['address', 'city', 'zip', 'country']]
      }

      return this.model.containsEmptyKeys(fields)
    },
    policyText() {
      return 'By clicking <b>CREATE</b> and submitting this form I confirm that' +
          ' the information either relates to myself and/or I have the authority' +
          ' and permission to submit the data on behalf of the person named above' +
          ' and such persons have been made aware of, and agree to, the terms of the' +
          ' <a href=\''+window.boUrl+'/policy'+'\' target="_blank">AFC Data Protection Policy.</a>'
    }
  },
  methods: {
    handlePhoneChange(newValue) {
      this.model.phone = newValue
    },
    handleSeasonChange() {
      this.model.teams = null
      this.$store.dispatch('teamsList/getTeams', {seasonId: this.model.season.seasonId})
    },
    createContact(data) {
      this.showLoader = true;
      data.contactType = this.contactType

      if (this.isValid) return null;

      this.$store.dispatch('contactsList/createContact', data).then(res => {
        if (!res.$error) {
          this.closePopup()
        } else {
          const errors = get(res, '$response.data.data.errors')
          if (errors) {
            const arr = []
            for (const key in errors) {
              const error = errors[key]
              arr.push(...error)
            }
            this.errors = arr
          } else {
            this.errors = ['Something went wrong']
          }
        }
        this.showLoader = false;
      })
    },
    updateContact(data) {
      this.showLoader = true;
      data.contactType = this.contactType

      if (this.isValid) return null;

      this.$store.dispatch('contactsList/updateContact', data).then(res => {
        if(!res.$error) {
          for (const key in this.contact) {
            this.contact[key] = this.model[key]
          }
          this.closePopup()
        } else {
          const errors = get(res, '$response.data.data.errors')
          if (errors) {
            const arr = []
            for (const key in errors) {
              const error = errors[key]
              arr.push(...error)
            }
            this.errors = arr
          } else {
            this.errors = ['Something went wrong']
          }
        }
        this.showLoader = false;
      })
    },
    closePopup() {
      this.$emit('closePopup')
    },
    fetchCountries(data) {
      this.$store.dispatch('countriesList/getCountries', data)
    },
    fetchCompetitionSeasons(data) {
      this.$store.dispatch('event/getCompetitionSeasons', data).then(() => {
       this.model.season = this.competitionSeasons.find(
           item => item.seasonId.toString() === this.$store.state.event.selectedSeason.seasonId.toString()
       )
      })
    },
  },
  created() {
    this.model = Object.assign(this.model, this.contact)
    this.fetchCountries()
    this.fetchCompetitionSeasons()
  }
}
</script>

<style lang="scss">
.ContactsPopup {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 100;
  padding: 20px;
  overflow: scroll;

  &__errors {
    color: darkred;
    font-size: 12px;
  }

  &__window {
    width: 600px;
    padding: 20px;
    border-radius: 20px;
    background: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &__container {
    padding: 20px;
  }

  &__close-popup {
    width: 100%;
    text-align: right;
    font-weight: bold;
    margin-bottom: 20px;
    span {
      cursor: pointer;
    }
  }

  &__hidden-label {
    .InputLabel__label {
      color: #fff;
    }
  }

  .btn-wrapper {
    margin-top: 30px;
  }

  .InputLayout {
    margin-bottom: 10px;
  }

  .Input__input, .multiselect, .InputLayout__slot {
    width: 100% !important;
  }

  .multiselect__single, .multiselect__option {
    text-transform: capitalize;
  }

  .Input__input {
    padding: 10px 15px;
  }

  .InputLabel__label {
    width: 150px;
    overflow: visible;
    font-weight: 400;
    font-size: 16px;
  }
}
</style>
