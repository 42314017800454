<template>
  <section class="MainContacts-View">
    <SpaceDescription>
      <Headline :size="1">Main Contacts</Headline>
    </SpaceDescription>

    <ContactsPopup
      v-if="showPopup"
      :type="type"
      contactType="contact"
      :contact="contactModel"
      @closePopup="onClose"
    />

    <ContactsListTable
      @contactCreate="onCreate"
      @contactUpdate="onUpdate"
    />
  </section>
</template>

<script>
import SpaceDescription from "@/components/SpaceDescription/SpaceDescription.component";
import Headline from "@/components/Headline/Headline.component";
import ContactsPopup from '@/components/Popup/ContactsPopup.component';
import ContactsListTable from '@/components/Table/tables/ContactsListTable.component';
import { ContactModel } from "@/classes/models/Contact.model";
import RouterNames from "@/router/route.names";

export default {
  components: {
    SpaceDescription,
    Headline,
    ContactsListTable,
    ContactsPopup
  },
  data: () => ({
    showPopup: false,
    contactModel: new ContactModel(),
    type: 'create'
  }),
  methods: {
    onClose() {
      this.showPopup = false;
      this.$store.dispatch('contactsList/getContacts');
    },
    onCreate() {
      this.type = 'create'
      this.contactModel = new ContactModel()
      this.showPopup = true
    },
    onUpdate(model) {
      this.type = 'update'
      this.contactModel = model
      this.showPopup = true
    },
    checkPermissions() {
      const permitted = this.$store.getters['user/haveAccessToTickets'];
      if (!permitted) this.$router.push({name: RouterNames.Home})
    }
  },
  created() {
    this.checkPermissions()
  }
}
</script>
